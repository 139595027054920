import React from "react"
import Layout from "../components/layout"
import Img from "gatsby-image"
import BackgroundImage from "gatsby-background-image"
import SEO from "../components/seo"
import Container from "../components/container"
import Page from "../components/page"
import styled from "@emotion/styled"
import ReactMarkdown from "react-markdown"
import { graphql } from "gatsby"
import { FaTwitter, FaInstagram, FaFacebookF} from "react-icons/fa"
import axios from "axios"
const isBrowser = typeof window !== "undefined"
const stripe = isBrowser
  ? window.Stripe("pk_live_x0U124WAv8PcLC1YAhrmeHql")
  : ""

const Styles = styled.div`
  min-height: 100vh;
  .gatsby-image-wrapper {
    border-radius: 2px;
  }

  .my-logo {
    width: 48px;
  }

  .my-title {
    color: white;
    font-size: 36px;
    font-weight: 100;
    max-width: 400px;
    display: block;
    margin: 0 auto;
  }

  .my-card {
    display: block;
    max-width: 180px;
    margin: 18px auto;
    margin-bottom: 0px;
  }

  .my-ellipse {
    display: block;
    max-width: 180px;
    margin: 0px auto;
    position: relative;
    bottom: 6px;
    margin-bottom: 12px;
  }

  .my-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    grid-gap: 24px;
  }

  .button.is-warning {
    display: block-inline;
    margin: 0 auto;
    margin-bottom: 24px;
    padding: 8px 24px;
    // font-weight: 100;
  }

  .my-footer {
    color: white;
    background: #0a0a0acc;
    font-weight: 100;
    font-size: 12px;

    .my-footer-grid {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
      text-align: center;
      grid-gap: 16px;

      @media screen and (min-width: 800px) {
        text-align: left;
        grid-template-columns: repeat(auto-fill, minmax( calc(50% - 16px), 1fr));
      }
    }
  }

  .icons {
    display: grid;
    width: 180px;
    font-size: 16px;
    grid-template-columns: repeat(auto-fill, minmax(33%, 1fr)); 
    margin: 0 auto;

    @media screen and (min-width: 800px) {
      float: right;
      margin: 0;
    }
  }

  .email {
    text-align: center;

    @media screen and (min-width: 800px) {
      text-align: right;
    }

    a {
      color: white;
    }
  }
`

export default class extends React.Component {
  componentDidMount() {
    this.getStripeSessionId()
    // this.getSku()

    // this.createSku();
  }

  createSku() {
    // let resp = axios({
    //   method: "post",
    //   url: `https://api.stripe.com/v1/skus?currency=usd&inventory=price=1500&product=prod_GVWja7QJnpwUzB&inventory[type]=infinte`,
    //   headers: {
    //     "Content-Type": "application/x-www-form-urlencoded",
    //     "Authorization": "Bearer sk_live_NPXI7HM8fO3VH5bCo3otrsjp00trvMZqVx",
    //   },
    // })
    //   .then(this.setStripeSessionId.bind(this))
    //   .catch(this.stripSessionError.bind(this))
  }

  getSku() {
    // let resp = axios({
    //   method: "get",
    //   url: `https://api.stripe.com/v1/skus`,
    //   headers: {
    //     "Content-Type": "application/x-www-form-urlencoded",
    //     "Authorization": "Bearer sk_live_NPXI7HM8fO3VH5bCo3otrsjp00trvMZqVx",
    //   },
    // })
    //   .then(this.setStripeSessionId.bind(this))
    //   .catch(this.stripSessionError.bind(this))
  }

  getStripeSessionId() {
    // https://stripe.com/docs/api/checkout/sessions/create
    let resp = axios({
      method: "post",
      url: `https://api.stripe.com/v1/checkout/sessions?payment_method_types[0]=card&cancel_url=https://google.com&success_url=https://example.com/success&mode=payment&line_items[0][amount]=9900&line_items[0][currency]=usd&line_items[0][name]=The last business card you'll ever need.&line_items[0][quantity]=1&line_items[0][name]=Last Card&line_items[0][images][]=https://res.cloudinary.com/djjldnjz7/image/upload/v1578523167/card_jmwejt.png`,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: "Bearer sk_live_NPXI7HM8fO3VH5bCo3otrsjp00trvMZqVx",
      },
    })
      .then(this.setStripeSessionId.bind(this))
      .catch(this.stripSessionError.bind(this))
  }
  setStripeSessionId(resp, a, b, c, d) {
    if (resp) {
      this.setState({ id: resp.data.id })
    }
  }

  stripSessionError(err, resp) {
    console.log(err, resp)
  }

  goToCheckout() {
    stripe
      .redirectToCheckout({
        // Make the id field from the Checkout Session creation API response
        // available to this file, so you can provide it as parameter here
        // instead of the {{CHECKOUT_SESSION_ID}} placeholder.
        sessionId: this.state.id,
        // items: [  {sku: "prod_GVWja7QJnpwUzB", quantity: 1}],
        // successUrl: 'https://getlastcard.com/',
        // cancelUrl: 'https://getlastcard.com/',
      })
      .then(function(result) {
        // If `redirectToCheckout` fails due to a browser or network
        // error, display the localized error message to your customer
        // using `result.error.message`.
      })
  }

  render() {
    const logo = this.props.data.logo.childImageSharp.fluid
    const rectangle = this.props.data.rectangle.childImageSharp.fluid
    const card = this.props.data.card.childImageSharp.fluid
    const ellipse = this.props.data.ellipse.childImageSharp.fluid

    return (
      <Layout>
        <SEO title="Home" />

        <Styles>
          <BackgroundImage fluid={rectangle} style={{ minHeight: "100vh" }}>
            <Container>
              <div className="my-logo">
                <Img fluid={logo} alt="Lastcard Logo" />
              </div>
            </Container>
            <Container>
              <div className="my-title has-text-centered">
                The last business card you'll ever need.
              </div>

              <Img
                fluid={card}
                alt="The Last Card You Will Ever Need"
                className="my-card"
              />
              <Img fluid={ellipse} alt="Ellipse" className="my-ellipse" />

              <div className="has-text-centered">
                <div
                  className="button is-warning"
                  onClick={this.goToCheckout.bind(this)}
                >
                  Pre-Order
                </div>
              </div>
            </Container>

            {/* <div className="my-footer">
              <Container>
                <div className="my-footer-grid">
                  <div>© {new Date().getFullYear()}, Last Card</div>

               

                  <div className="email">
                  <div className="icons">
                    <div>
                      <a href="#" target="_blank" ref="noopener noreferrer">
                    <FaTwitter /> 
                    </a>
                    </div>

                    <div>
                    <a href="#" target="_blank" ref="noopener noreferrer">
                    <FaInstagram /> 
                    </a>
                    </div>

                    <div>
                    <a href="#" target="_blank" ref="noopener noreferrer">
                    <FaFacebookF/>
                    </a>
                    </div>
                  
                  </div>
                  </div>
                </div>
              </Container>
            </div> */}
          </BackgroundImage>
        </Styles>
      </Layout>
    )
  }
}

export const query = graphql`
  {
    logo: file(relativePath: { eq: "logo.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }

    rectangle: file(relativePath: { eq: "rectangle.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }

    card: file(relativePath: { eq: "lastcard.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }

    ellipse: file(relativePath: { eq: "ellipse.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
